.offer-block{
  margin-top: 20px;
  margin-bottom: 20px;
  @media ($bp-larger-than-desktop) {
    margin-top: 40px;
    margin-bottom: 50px;
  }
  &__img{
    width: 100%;
    max-width: 100%;
  }
}
.offer-list{
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;

  &__text{
    margin-bottom: 30px;
    p{
      font-size: 14px;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 23px;
      line-height: 130%;
      @media ($bp-larger-than-tablet) {
        font-size: 24px;
      }
    }
  }
}

#leadform{
  padding: 50px;
  max-width: 900px;
  background-color: #e9e9e9;
}